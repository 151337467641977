/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext } from 'react'

export type ILoggerContext = {
  error: (message: string, params: any, error?: Error) => void
  warn: (message: string, params: any, error?: Error) => void
  info: (message: string, params: any, error?: Error) => void
}

export const LoggerContext = createContext<ILoggerContext>({
  error: (message: string, params: any, error?: Error) =>
    console.error(message, params, error),
  warn: (message: string, params: any, error?: Error) =>
    console.warn(message, params, error),
  info: (message: string, params: any, error?: Error) =>
    console.info(message, params, error)
})

const useLogger = (): ILoggerContext => useContext(LoggerContext)

export default useLogger
