import {
  AccessAvailability,
  AccessFrequency,
  AccessHours,
  AccessPermission,
  CardType,
  CheckoutType,
  CustomerType,
  Features,
  IGoogleAutocompleteSuggestion,
  IndoorOutdoor,
  Interest,
  LocationType,
  MessageEventType,
  PropertyType,
  SizeFilter,
  StorageTime,
  UseOfSpace,
  UserLocationsFilter,
  VehicleType
} from '../types/graphql'

export const DEFAULT_CENTER = { lat: 19.4326077, lng: -99.133208 }

export const CENTER_MAP: { [key in string]: { lat: number; lng: number } } = {
  ['mty']: { lat: 25.682, lng: -100.315 },
  ['cdmx']: { lat: 19.4326077, lng: -99.133208 }
}

export const DEFAULT_ZOOM = 12
export const DEFAULT_PRICE_RANGE: [number, number] = [0, 500]

export const NEAR_USER = 'NEAR_USER'
export const NEAR_USER_DESCRIPTION = 'SEARCH_MANAGER.NEAR_TO_ME'

export const PAGE_SIZE = 12

export const LOCATION_TYPE_MAP: {
  [key in LocationType]: {
    icon?: string
    deprecated?: boolean
  }
} = {
  [LocationType.Warehouse]: { icon: 'si-warehouse' },
  [LocationType.Parking]: { icon: 'si-stop' },
  [LocationType.Room]: { icon: 'si-bed' },
  [LocationType.Local]: { icon: 'si-retail-space' },
  [LocationType.Land]: { icon: 'si-grounds' },

  [LocationType.Yard]: { icon: 'si-my-rentals' },
  [LocationType.Terrace]: { icon: 'si-house-plus' },
  [LocationType.Shed]: { icon: 'si-house' },
  [LocationType.ShippingContainer]: { icon: 'si-container' },
  [LocationType.SelfStorageUnit]: { icon: 'si-mezzanine' },

  [LocationType.Closet]: { icon: 'si-entrance' },
  [LocationType.Basement]: { icon: 'si-basement' },
  [LocationType.Garage]: { icon: 'si-garage' },
  [LocationType.Attic]: { icon: 'si-attic' },
  [LocationType.Other]: {},

  // Deprecated
  [LocationType.House]: {
    icon: 'si-house',
    deprecated: true
  },
  [LocationType.MiniWinery]: {
    icon: 'si-container',
    deprecated: true
  },
  [LocationType.Penthouse]: {
    icon: 'si-attic',
    deprecated: true
  }
}

export const INDOOR_OUTDOOR_MAP = {
  [IndoorOutdoor.Indoor]: {
    label: 'Interior',
    description: 'Cuartos, bodegas, cocheras, etc',
    icon: 'si-house'
  },
  [IndoorOutdoor.Outdoor]: {
    label: 'Exterior',
    description: 'Patios, terrenos, estacionamientos, etc',
    icon: 'si-grounds'
  },
  [IndoorOutdoor.OutdoorUncovered]: {
    label: 'Exterior techado',
    description: 'Estacionamientos techados, etc.',
    icon: 'si-store'
  }
}

export const PROPERTY_TYPE_MAP = {
  [PropertyType.Residential]: {
    label: 'Residencial',
    description: 'Vivienda o propiedad personal',
    icon: 'si-residential'
  },
  [PropertyType.Commercial]: {
    label: 'Comercial',
    description:
      'Propiedad comercial o de negocio (oficina, local, bodega, plaza, estacionamiento, etc.)',
    icon: 'si-commercial'
  }
}

export const CAN_STORE_VEHICLE_MAP = {
  '0': {
    label: 'Sí',
    description: 'Sí puedo y estoy dispuesto a almacenar vehículos',
    icon: 'si-vehicle-entrance'
  },
  '1': {
    label: 'No',
    description: 'No puedo almacenar vehículos',
    icon: 'si-box'
  }
}

export const DISCOUNT_MAP = {
  '0': {
    label: 'Sí, ofrezco descuento del 50% el primer mes',
    description: 'El Rentero deberá quedarse mínimo dos meses',
    icon: 'si-offert'
  },
  '1': {
    label: 'No, mantenlo a precio completo',
    icon: 'si-thumbs-down'
  }
}

export const ACCESS_PERMISSION_MAP: { [key in AccessPermission]: string } = {
  [AccessPermission.NoNotice]: 'No (sin cita previa)',
  [AccessPermission.Notice]:
    'Sí (los Renteros deberán avisar antes de cada visita)'
}

export const ACCESS_FREQUENCY_MAP: { [key in AccessFrequency]: string } = {
  [AccessFrequency.MonToFri]: 'Solo lunes a viernes',
  [AccessFrequency.Weekends]: 'Solo fin de semana',
  [AccessFrequency.Anyday]: 'Cuando sea'
}

export const ACCESS_HOURS_MAP: { [key in AccessHours]: string } = {
  [AccessHours.Anytime]: 'Cualquier hora',
  [AccessHours.DaytimeHours]: 'Horario laboral (9am - 6pm)',
  [AccessHours.EveningHours]: 'Tardes (6pm en adelante)'
}

export const ACCESS_AVAILABILITY_MAP: { [key in AccessAvailability]: string } =
  {
    [AccessAvailability.Daily]: 'Diario',
    [AccessAvailability.Weekly]: 'Semanal (algunas veces por semana)',
    [AccessAvailability.Monthly]: 'Mensual (algunas veces al mes)',
    [AccessAvailability.Unfrequently]:
      'Con poca frecuencia (menos de una vez al mes)'
  }

export const FEATURES_MAP: {
  [key in Features]: {
    label: string
    icon?: string
    description?: string
    deprecated?: boolean
  }
} = {
  [Features.PrivateEntrance]: {
    label: 'Entrada independiente',
    icon: 'si-entrance'
  },
  [Features.PrivateSpace]: { label: 'Espacio privado', icon: 'si-privacy' },
  [Features.SecurityCamera]: {
    label: 'Cámara de seguridad',
    icon: 'si-camera'
  },
  [Features.FloorLevel]: {
    label: 'A nivel del piso',
    icon: 'si-entrance'
  },
  [Features.PetFree]: {
    label: 'Libre de mascotas',
    icon: 'si-close'
  },

  [Features.SmokeDetectors]: {
    label: 'Detector de humo',
    icon: 'si-fire-alarm'
  },
  [Features.Fumigated]: {
    label: 'Fumigado',
    icon: 'si-house-shield'
  },
  [Features.ClimateControl]: {
    label: 'Control de temperatura',
    icon: 'si-temperature-control'
  },
  [Features.Surveillance]: {
    label: 'Vigilancia',
    icon: 'si-house-shield'
  },
  [Features.VehicleAccess]: {
    label: 'Acceso Vehicular',
    icon: 'si-vehicle-entrance'
  },

  [Features.Elevator]: { label: 'Elevador', icon: 'si-elevator' },
  [Features.AlarmSystem]: { label: 'Sistema de alarma', icon: 'si-alarms' },
  [Features.AntiFireSystem]: {
    label: 'Sistema contra incendio',
    icon: 'si-fire-alarm'
  },
  [Features.Mezzanine]: { label: 'Andén/Mezzanine', icon: 'si-mezzanine' },
  [Features.Water]: { label: 'Servicio de agua y drenaje', icon: 'si-water' },

  [Features.TruckOrTrailerAccess]: {
    label: 'Acceso a camiones/tráilers',
    icon: 'si-delivery-truck'
  },
  [Features.Railyard]: { label: 'Patio de maniobras', icon: 'si-signal' },
  [Features.Gas]: { label: 'Gas', icon: 'si-gas' },
  [Features.Toilets]: { label: 'Baños', icon: 'si-bathroom' },
  [Features.ElectricalInstallation]: {
    label: 'Instalación eléctrica (luz, contactos, interruptores)',
    icon: 'si-light'
  },

  [Features.Shelves]: {
    label: 'Estantes/Racks/Bastidores',
    icon: 'si-mezzanine'
  },
  [Features.Reception]: { label: 'Recepcionista', icon: 'si-user' },
  [Features.Offices]: { label: 'Oficinas', icon: 'si-identificator' },
  [Features.Cafeteria]: { label: 'Comedor', icon: 'si-store' },
  [Features.Tank]: { label: 'Cisterna', icon: 'si-cistern' },

  [Features.LockedArea]: {
    label: 'Área bloqueada (candado, clave/código de acceso)',
    icon: 'si-lock'
  },
  [Features.Waterproofed]: { label: 'Impermeabilizado', icon: 'si-water' },
  [Features.Tinaco]: { label: 'Tinaco', icon: 'si-cistern' },
  [Features.Stairs]: { label: 'Escaleras', icon: 'si-stairs' },
  [Features.ArmoredSpace]: { label: 'Espacio blindado', icon: 'si-lock' },

  // Deprecated
  [Features.Light]: {
    label: 'Instalación eléctrica',
    icon: 'si-light',
    deprecated: true
  },
  [Features.MeetingRooms]: {
    label: 'Sala de juntas',
    icon: 'si-meeting-room',
    deprecated: true
  },
  [Features.AirConditioner]: {
    label: 'Aire acondicionado',
    icon: 'si-air-conditioning',
    deprecated: true
  },
  [Features.ClassificationYard]: {
    label: 'Patio de maniobras',
    icon: 'si-signal',
    deprecated: true
  },
  [Features.IndependentEntrance]: {
    label: 'Entrada Independiente',
    icon: 'si-entrance',
    deprecated: true
  },
  [Features.PestControl]: {
    label: 'Control de plagas',
    icon: 'si-gas',
    deprecated: true
  },
  [Features.Bathrooms]: {
    label: 'Baños',
    icon: 'si-bathroom',
    deprecated: true
  },
  [Features.ShieldedSpace]: {
    label: 'Espacio techado',
    icon: 'si-store',
    deprecated: true
  }
}

export const USE_OF_SPACE_MAP: { [key in UseOfSpace]: string } = {
  [UseOfSpace.None]: 'No sé',
  [UseOfSpace.Commercial]: 'Comercial',
  [UseOfSpace.Residencial]: 'Habitacional',
  [UseOfSpace.Industrial]: 'Industrial',
  [UseOfSpace.Mixed]: 'Mixto'
}

export const STORAGE_TIME_MAP: { [key in StorageTime]: string } = {
  [StorageTime.Month]: 'Un mes',
  [StorageTime.FewMonths]: 'Un par de meses',
  [StorageTime.Year]: 'Un año',
  [StorageTime.FewYears]: 'Un par de años',
  [StorageTime.Indefinitely]: 'Indefinido'
}

export const CARD_TYPE_MAP: { [key in CardType]: string } = {
  [CardType.Credit]: 'Credito',
  [CardType.Debit]: 'Debito'
}
export const VEHICLE_TYPE_MAP: { [key in VehicleType]: string } = {
  [VehicleType.Car]: 'Coche',
  [VehicleType.Boat]: 'Bote',
  [VehicleType.Motorcycle]: 'Motocicleta'
}

export const SERVICE_FEE = 19.69

export const MAX_NUMBER_OF_UPLOADS = 15

export const NEAR_USER_OPTION: IGoogleAutocompleteSuggestion = {
  __typename: 'GoogleAutocompleteSuggestion',
  placeId: NEAR_USER,
  description: NEAR_USER_DESCRIPTION
}

export const MTY_OPTION: IGoogleAutocompleteSuggestion = {
  __typename: 'GoogleAutocompleteSuggestion',
  placeId: 'ChIJ9fg3tDGVYoYRlJjIasrT06M',
  description: 'Monterrey, N.L.'
}

export const CDMX_OPTION: IGoogleAutocompleteSuggestion = {
  __typename: 'GoogleAutocompleteSuggestion',
  placeId: 'ChIJB3UJ2yYAzoURQeheJnYQBlQ',
  description: 'Ciudad de México, CDMX'
}

export const CHECKOUT_STATUS_MAP: { [tab in CheckoutType]: string } = {
  [CheckoutType.Pending]: 'Pendiente',
  [CheckoutType.Active]: 'Activa',
  [CheckoutType.Past]: 'Anterior',
  [CheckoutType.UpComing]: 'Próximo'
}

export const CHECKOUT_COLOR_MAP: { [tab in CheckoutType]: string } = {
  [CheckoutType.Pending]: 'bg-yellow-500',
  [CheckoutType.Active]: 'bg-blue-500',
  [CheckoutType.Past]: 'bg-black-400',
  [CheckoutType.UpComing]: 'bg-green-500'
}

export const CHECKOUT_TAB_MAP: { [tab in CheckoutType]: string } = {
  [CheckoutType.Pending]: 'Pendientes',
  [CheckoutType.Active]: 'Activas',
  [CheckoutType.Past]: 'Anteriores',
  [CheckoutType.UpComing]: 'Próximo'
}

/* eslint-disable */
export const USER_LOCATIONS_TYPE_MAP: { [tab in UserLocationsFilter]: string } =
  {
    [UserLocationsFilter.All]: 'Todos',
    [UserLocationsFilter.Pending]: 'Pendientes',
    [UserLocationsFilter.Active]: 'Activos',
    [UserLocationsFilter.Inactive]: 'Inactivos',
    [UserLocationsFilter.Drafts]: 'Borradores'
  }

export const TOPBAR_HEIGHT_MD = 72
export const TOPBAR_HEIGHT_SM = 56

export const DEFAULT_FILTERS = {
  canStoreVehicle: undefined,
  size: SizeFilter.Any,
  priceRange: DEFAULT_PRICE_RANGE,
  zoom: DEFAULT_ZOOM
}

export const INTEREST_MAP: {
  [key in Interest]: { label: string; description: string; icon: string }
} = {
  [Interest.Rent]: {
    label: 'Rentero',
    description: 'Encuentra espacio',
    icon: 'si-location'
  },
  [Interest.Host]: {
    label: 'Anfitrión',
    description: 'Ofrece almacenamiento',
    icon: 'si-house'
  }
}

export const CUSTOMER_TYPE_MAP: {
  [key in CustomerType]?: { label: string; description: string; icon: string }
} = {
  [CustomerType.RentIndividual]: {
    label: 'Rentero',
    description: 'Encuentra espacio',
    icon: 'si-location-dot'
  },
  [CustomerType.HostIndividual]: {
    label: 'Anfitrión',
    description: 'Ofrece tu espacio',
    icon: 'si-house'
  },
  [CustomerType.HostCorporate]: {
    label: 'Corporativo',
    description: 'Ofrece espacio de tu empresa',
    icon: 'si-warehouse'
  },
  [CustomerType.HostBroker]: {
    label: 'Broker',
    description: 'Ofrece espacio de terceros',
    icon: 'si-user-single'
  }
}

export const MESSAGE_EVENT_TYPE_MAP: { [key in MessageEventType]: string } = {
  [MessageEventType.RequestSent]: 'Solicitud enviada',
  [MessageEventType.RequestAccepted]: 'Solicitud aceptada',
  [MessageEventType.RequestRejected]: 'Solicitud rechazada',
  [MessageEventType.CheckoutStart]: 'Reserva iniciada',
  [MessageEventType.CheckoutCancelled]: 'Reserva cancelada',
  [MessageEventType.CheckoutEnded]: 'Reserva finalizada'
}
